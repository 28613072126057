<template>
  <div class="flex flex-col p-3 pt-6 sm:p-6">
    <Khead />

    <div class="flex justify-center">
      <div
        class=" mb-32 bg-white mt-8 sm:w-frame  border rounded"
        :class="!signSuccess ? '' : ''"
      >
        <div class=" container mx-auto px-3 sm:px-4">
          <div class="m-5 sm:m-10 mb-8">
            <div
              v-if="signSuccess"
              class=" flex flex-col items-center justify-center"
            >
              <Iconbigcheck />
              <span class="mt-4 text-xl text-center font-semibold">{{
                $t("sign.successTitle")
              }}</span>
              <span class="mt-1 text-center">{{
                $t("sign.successSubtiitle")
              }}</span>
            </div>

            <div v-else class="mb-6  flex items-center justify-center">
              <span><IconLogin /></span>
              <span class=" ml-2 text-xl sm:text-3xl font-semibold"
                >Connexion</span
              >
            </div>

            <label class="mt-3 text-sm">{{ $t("sign.loginLabel") }}</label>
            <div
              class="mt-1 bg-gray-300 inline-flex w-full"
              style="border-radius: 1rem;"
            >
              <button
                @click="loginBy"
                type="button"
                :class="
                  !telephone
                    ? 'bg-white transition-all duration-500 transform '
                    : ''
                "
                class="focus:outline-none m-2 text-gray-800 font-bold py-2 px-4 w-1/2 flex justify-center"
                style="border-radius: 0.75rem;"
              >
                <Iconsmail />
                <span class="ml-1 text-xs">Email</span>
              </button>
              <button
                @click="loginBy"
                type="button"
                :class="
                  telephone
                    ? 'bg-white transition-all duration-500 transform '
                    : ''
                "
                class="focus:outline-none m-2 text-gray-800 font-bold py-2 px-4 w-1/2 flex justify-center"
                style="border-radius: 0.75rem;"
              >
                <Iconphone />
                <span class="ml-1 text-xs">{{
                  $t("rechargeSuccess.phone")
                }}</span>
              </button>
            </div>

            <div
              v-show="userNotFound || pwdInvalid"
              class="flex items-end justify-center mt-2"
            >
              <IconError /><i class="text-tex-2 ml-1">{{
                $t("sign.invalidUser")
              }}</i>
            </div>
            <div
              v-show="userBlocked"
              class="flex items-end justify-center mt-2"
            >
              <IconError /><i class="text-tex-2 ml-1">{{
                $t("sign.blockedUser")
              }}</i>
            </div>

            <form
              v-show="!telephone"
              @submit.prevent="submitToConnectByMail"
              data-vv-scope="form-1"
            >
              <div class="mt-4">
                <label class="text-base" for="officeEmail">Email</label>
                <br />
                <div
                  class="bg-white h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded flex flex-row items-center"
                  :class="{
                    ' border-tex-2':
                      submittedMail && errors.has('form-1.email'),
                  }"
                >
                  <span class="p-3">
                    <Iconmail />
                  </span>
                  <input
                    class="leading-tight w-full focus:outline-none focus:bg-white"
                    id="email"
                    name="email"
                    type="text"
                    v-validate="'required|email'"
                    v-model="user.email"
                  />
                </div>
                <div
                  v-show="submittedMail && errors.has('form-1.email')"
                  class="flex items-center mt-2"
                >
                  <IconError />
                  <span class="ml-1 text-tex-2">{{
                    $t("sign.errorMail")
                  }}</span>
                </div>
              </div>
              <div class="mt-4">
                <label class="text-base " for="pwd">{{ $t("sign.pwd") }}</label>
                <br />
                <div
                  class=" bg-white h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded flex flex-row"
                  :class="{
                    ' border-tex-2': submittedMail && errors.has('form-1.pwd'),
                  }"
                >
                  <input
                    class="p-3 w-11/12 py-2 leading-tight focus:outline-none focus:bg-white"
                    id="pwd"
                    name="pwd"
                    autocomplete="current-password"
                    :type="pwdType"
                    v-validate="'required|min:8'"
                    v-model="user.password"
                  />
                  <Iconeye
                    class="m-2"
                    @click="switchPwdType"
                    v-if="pwdType === 'password'"
                  />
                  <Iconeyeoff
                    class="m-2"
                    @click="switchPwdType"
                    v-if="pwdType === 'text'"
                  />
                </div>
              </div>

              <div class="mt-4 text-radio-100">
                <button
                  type="button"
                  class="focus:outline-none"
                  @click="pwdReset"
                >
                  {{ $t("sign.forgetPwd") }}
                </button>
              </div>

              <button
                type="submit"
                class="mt-4 text-white py-2 w-full h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 rounded focus:outline-none"
              >
                <div v-show="!request">{{ $t("sign.connect") }}</div>
                <LoaderCss class="ml-16" v-show="request" />
              </button>
            </form>

            <form
              v-show="telephone"
              @submit.prevent="submitToConnectByPhone"
              data-vv-scope="form-2"
            >
              <div class="mt-4">
                <label class="text-base" for="officePhone">{{
                  $t("rechargeSuccess.phone")
                }}</label>
                <br />
                <div>
                  <vue-tel-input
                    inputClasses=""
                    placeholder=""
                    name="phone"
                    inputId="phone"
                    :enabledCountryCode="true"
                    :validCharactersOnly="true"
                    @country-changed="countryChanged"
                    wrapperClasses="h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded"
                    v-model="user.phone"
                  >
                  </vue-tel-input>
                  <div v-show="phoneError" class="flex items-center mt-1">
                    <IconError /><span class="ml-1 text-tex-2">{{
                      $t("sign.errorNumber")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <label class="text-base " for="pwd">{{ $t("sign.pwd") }}</label>
                <br />
                <div
                  class=" bg-white h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded flex flex-row"
                  :class="{
                    ' border-tex-2': submittedPhone && errors.has('form-2.pwd'),
                  }"
                >
                  <input
                    class="p-3 w-11/12 py-2 leading-tight focus:outline-none focus:bg-white"
                    id="pwd"
                    name="pwd"
                    autocomplete="current-password"
                    :type="pwdType"
                    v-validate="'required|min:8'"
                    v-model="user.password"
                  />
                  <Iconeye
                    class="m-2"
                    @click="switchPwdType"
                    v-if="pwdType === 'password'"
                  />
                  <Iconeyeoff
                    class="m-2"
                    @click="switchPwdType"
                    v-if="pwdType === 'text'"
                  />
                </div>
              </div>

              <div class="mt-4 text-radio-100">
                <button
                  type="button"
                  class="focus:outline-none"
                  @click="pwdReset"
                >
                  {{ $t("sign.forgetPwd") }}
                </button>
              </div>

              <button
                type="submit"
                class="mt-4 text-white py-2 w-full h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 rounded focus:outline-none"
              >
                <div v-show="!request">{{ $t("sign.connect") }}</div>
                <LoaderCss class="ml-16" v-show="request" />
              </button>
            </form>

            <div v-show="!signSuccess" class="mt-4">
              <span>{{ $t("sign.notAccount") }}</span>
              <router-link to="/recharges/register" class="underline">{{
                $t("sign.notRegister")
              }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LoaderCss from "@/components/LoaderCss";
import Iconbigcheck from "../../assets/svg/icons/bigcheck.svg?inline";
import Iconeye from "../../assets/svg/icons/eye.svg?inline";
import Iconeyeoff from "../../assets/svg/icons/eyeoff.svg?inline";
import Iconmail from "@/assets/svg/icons/mail.svg?inline";
import Iconsmail from "@/assets/svg/icons/smallmail.svg?inline";
import Iconphone from "@/assets/svg/icons/smallphone.svg?inline";
import IconError from "@/assets/svg/icons/errorIcon.svg?inline";
import IconLogin from "@/assets/svg/login.svg?inline";
import Khead from "@/components/partials/Khead";
import { VueTelInput } from "vue-tel-input";
export default {
  components: {
    LoaderCss,
    Iconbigcheck,
    Iconeye,
    Iconeyeoff,
    Iconmail,
    Khead,
    Iconsmail,
    Iconphone,
    IconLogin,
    IconError,
    VueTelInput,
  },
  data() {
    return {
      request: false,
      pwdType: "password",
      submittedMail: false,
      submittedPhone: false,
      user: {
        email: "",
        phone: "",
        password: "",
      },
      pwdInvalid: false,
      userBlocked: false,
      userNotFound: false,
      telephone: false,
      phoneError: false,
      countryCode: null,
    };
  },
  watch: {
    user: {
      deep: true,
      handler() {
        if (this.user.email || this.user.phone || this.user.password) {
          this.pwdInvalid = false;
          this.userBlocked = false;
          this.userNotFound = false;
          this.phoneError = false;
          this.submittedMail = false;
        }
      },
    },
  },
  computed: {
    signSuccess() {
      return this.$store.getters.getSIgnSuccess;
    },
  },
  methods: {
    countryChanged(country) {
      this.countryCode = country.dialCode;
    },
    loginBy() {
      this.telephone = !this.telephone;
    },
    pwdReset() {
      this.$router.push("/resetpwd");
    },
    switchPwdType() {
      this.pwdType = this.pwdType === "password" ? "text" : "password";
    },

    submitToConnectByMail() {
      this.pwdInvalid = false;
      this.userBlocked = false;
      this.userNotFound = false;
      this.submittedMail = true;
      this.$validator.validateAll("form-1").then((valid) => {
        if (valid) {
          this.request = true;
          axios
            .post("/users/sign_in", {
              loginByPhone: false,
              username: this.user.email,
              password: this.user.password,
            })
            .then((res) => {
              console.log(res);
              const userData = res.data;
              this.$store.commit("mutSignSuccess", false);
              this.$store.commit("mutAuthUser", userData);
              localStorage.setItem("token", userData.token);
              this.request = false;
              this.$router.push("/dashboard");
            })
            .catch((err) => {
              this.request = false;
              console.log(err.response);
              const message = err.response.data.message;
              if (message == "invalid_password") this.pwdInvalid = true;
              else if (message == "user_blocked") this.userBlocked = true;
              else this.userNotFound = true;
            });
        }
      });
    },
    submitToConnectByPhone() {
      this.pwdInvalid = false;
      this.userBlocked = false;
      this.userNotFound = false;
      this.user.phone = this.user.phone.replace(/\s+/g, "");
      if (this.user.phone.length >= 8) {
        this.submittedPhone = true;
        this.$validator.validateAll("form-2").then((valid) => {
          if (valid) {
            this.request = true;
            axios
              .post("/users/sign_in", {
                loginByPhone: true,
                username: this.countryCode + this.user.phone,
                password: this.user.password,
              })
              .then((res) => {
                console.log(res);
                const userData = res.data;
                this.$store.commit("mutSignSuccess", false);
                this.$store.commit("mutAuthUser", userData);
                localStorage.setItem("token", userData.token);
                this.request = false;
                this.$router.push("/dashboard");
              })
              .catch((err) => {
                this.request = false;
                console.log(err.response);
                const message = err.response.data.message;
                if (message == "invalid_password") this.pwdInvalid = true;
                else if (message == "user_blocked") this.userBlocked = true;
                else this.userNotFound = true;
              });
          }
        });
      } else {
        this.phoneError = true;
      }
    },
  },
  mounted() {
    if (this.$store.state.subscribeByPhone == true) this.telephone = true;
  },
};
</script>

<style scoped>
.vue-tel-input {
  border-radius: 3px;
  display: flex;
  border: 1px solid #c6d6ec;
  text-align: left;
}

.vue-tel-input:hover {
  border-color: #a5bce0;
}
.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: #4661b9;
}

.vue-tel-input:focus {
  outline: none !important;
  border-color: violet;
}

::v-deep .vti__dropdown,
::v-deep .vti__dropdown.open:focus,
::v-deep .vti__dropdown.open:focus-within {
  outline-style: none;
  border-top-left-radius: 0.125rem /* 2px */;
  border-bottom-left-radius: 0.125rem /* 2px */;
}
</style>
